import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { UserLoginService } from './services/user-login.service';
import { CognitoUtil, LoggedInCallback } from './services/cognito.service';
import { AwsUtil } from './services/aws.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationFrench } from 'app/navigation/i18n/fr';
import { locale as navigationSpanish } from 'app/navigation/i18n/es';
import { APIService } from './services/api.service';
import { User } from './classes/user';

@Component({
  selector: 'app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, LoggedInCallback {
  fuseConfig: any;
  navigation: any;
  userLang: string;
  cognitoUser: any;
  user: User;

  // Private
  private unsubscribeAll: Subject<any>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private fuseConfigService: FuseConfigService,
    private fuseNavigationService: FuseNavigationService,
    private fuseSidebarService: FuseSidebarService,
    private fuseSplashScreenService: FuseSplashScreenService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private platform: Platform,
    public userService: UserLoginService,
    public cognito: CognitoUtil,
    public awsUtil: AwsUtil,
    private apiService: APIService,
  ) {
    // Get default navigation
    this.navigation = navigation;

    // Register the navigation to the service
    this.fuseNavigationService.register('main', this.navigation);

    // Set the main navigation as our current navigation
    this.fuseNavigationService.setCurrentNavigation('main');

    // Add languages
    this.translateService.addLangs(['en', 'fr', 'es']);

    // Set the default language
    // this.translateService.setDefaultLang('fr');
    if (
      localStorage.getItem('userPreferredLang') === null ||
      localStorage.getItem('userPreferredLang') === '' ||
      localStorage.getItem('userPreferredLang') === 'undefined'
    ) {
      if (navigator.language === 'en-GB' || navigator.language === 'en-US') {
        this.translateService.setDefaultLang('en');
      } else if (navigator.language === 'es-ES') {
        this.translateService.setDefaultLang('es');
      } else {
        this.translateService.setDefaultLang('fr');
      }
    } else if (localStorage.getItem('userPreferredLang')) {
      this.translateService.setDefaultLang(localStorage.getItem('userPreferredLang'));
    } else {
      this.translateService.setDefaultLang('fr');
    }

    // Set the navigation translations
    this.fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationFrench, navigationSpanish);

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix Start
     * ----------------------------------------------------------------------------------------------------
     */

    /**
     * If you are using a language other than the default one, i.e. Turkish in this case,
     * you may encounter an issue where some of the components are not actually being
     * translated when your app first initialized.
     *
     * This is related to ngxTranslate module and below there is a temporary fix while we
     * are moving the multi language implementation over to the Angular's core language
     * service.
     */

    // Set the default language to 'en' and then back to 'tr'.
    // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
    // been selected and there is no way to force it, so we overcome the issue by switching
    // the default language back and forth.
    /**
         setTimeout(() => {
            this.translateService.setDefaultLang('en');
            this.translateService.setDefaultLang('tr');
         });
    */

    /**
     * ----------------------------------------------------------------------------------------------------
     * ngxTranslate Fix End
     * ----------------------------------------------------------------------------------------------------
     */

    // If the platform is mobile, redirect to the  mobile store
    if (this.platform.ANDROID) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.beepings.app&hl=fr';
    }
    if (this.platform.IOS) {
      window.location.href = 'https://apps.apple.com/fr/app/beepings/id1126066132';
    }

    // Set the private defaults
    this.unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to config changes
    this.fuseConfigService.config.pipe(takeUntil(this.unsubscribeAll)).subscribe(config => {
      this.fuseConfig = config;

      // Boxed
      if (this.fuseConfig.layout.width === 'boxed') {
        this.document.body.classList.add('boxed');
      } else {
        this.document.body.classList.remove('boxed');
      }

      // Color theme - Use normal for loop for IE11 compatibility
      for (let i = 0; i < this.document.body.classList.length; i++) {
        const className = this.document.body.classList[i];

        if (className.startsWith('theme-')) {
          this.document.body.classList.remove(className);
        }
      }

      this.document.body.classList.add(this.fuseConfig.colorTheme);
    });

    console.log('AppComponent: Checking if the user is already authenticated');
    this.userService.isAuthenticated(this);

    // this.getUser();
  }

  getUser() {
    this.cognitoUser = this.cognito.getCurrentUser();

    return this.apiService.getUser(this.cognitoUser.username).subscribe(
      user => {
        this.user = user;
      },
      err => {
        console.log(err);
      },
    );

    // return this.apiService
    //   .getUser(this.cognitoUser.username)
    //   .then(user => {
    //     this.user = user;
    //   })
    //   .catch(err => console.log(err));
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log('AppComponent: the user is authenticated: ' + isLoggedIn);
    const mythis = this;
    this.cognito.getIdToken({
      callback() {},
      callbackWithParam(token: any) {
        // Include the passed-in callback here as well so that it's executed downstream
        console.log('AppComponent: calling initAwsService in callback');
        mythis.awsUtil.initAwsService(null, isLoggedIn, token);
      },
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  toggleSidebarOpen(key): void {
    this.fuseSidebarService.getSidebar(key).toggleOpen();
  }
}
