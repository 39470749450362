export const locale = {
  lang: 'en',
  data: {
    NAV: {
      DEVICES: 'Devices',
      MAIN: {
        TITLE: 'Zen mine',
        BADGE: '25'
      }
    }
  }
};
