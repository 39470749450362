const dateFilter = {
  id: 'date',
  initial: 'none',
  states: {
    none: {},
    today: {
      on: {
        TODAY: {
          target: ['none', '#history.loadingHistory'],
          actions: 'unsetDateRange',
        },
      },
    },
    yesterday: {
      on: {
        YESTERDAY: {
          target: ['none', '#history.loadingHistory'],
          actions: 'unsetDateRange',
        },
      },
    },
    range: {
      on: {
        CLOSE: {
          target: ['#modals.closed'],
        },
      },
    },
  },
  on: {
    TODAY: {
      target: ['.today', '#history.loadingHistory'],
      actions: 'changeDateRange',
    },
    YESTERDAY: {
      target: ['.yesterday', '#history.loadingHistory'],
      actions: 'changeDateRange',
    },
    CALENDAR: {
      target: ['#modals.calendar'],
    },
    RESET: {
      target: ['.none', '#history.loadingHistory'],
      actions: 'unsetDateRange',
    },
  },
};

const pointInfo = {
  initial: 'hideDate',
  states: {
    hideDate: {
      on: {
        HOVER: 'showDate',
      },
    },
    showDate: {
      on: {
        OUT: 'hideDate',
      },
    },
  },
};

const modals = {
  id: 'modals',
  initial: 'closed',
  states: {
    closed: {},
    calendar: {
      on: {
        CHANGE_DATE_RANGE: {
          target: ['#date.range', '#history.loadingHistory'],
          actions: 'changeDateRange',
        },
      },
    },
    share: {
      initial: 'loading',
      states: {
        loading: {
          invoke: {
            id: 'getEnclosureSharing',
            src: 'getEnclosureSharing',
          },
          on: {
            SUCCESS: {
              target: 'list',
              actions: 'assignEnclosureShares',
            },
          },
        },
        deleting: {
          invoke: {
            src: 'deleteEnclosureSharing',
          },
          on: {
            SUCCESS: 'loading',
            FAILURE: 'list',
          },
        },
        adding: {
          invoke: {
            src: 'addEnclosureSharing',
          },
          on: {
            SUCCESS: 'loading',
            FAILURE: 'list',
          },
        },
        list: {
          on: {
            DELETE: 'deleting',
            ADD: 'adding',
          },
        },
      },
    },
    settings: {
      initial: 'loading',
      states: {
        loading: {
          invoke: {
            src: 'getDeviceSettings',
          },
          on: {
            SUCCESS: {
              target: 'list',
              actions: 'assignDeviceSettings',
            },
            FAILURE: 'list',
          },
        },
        updating: {
          invoke: {
            src: 'updateDeviceSettings',
          },
          on: {
            SUCCESS: 'list',
            FAILURE: 'list',
          },
        },
        list: {
          on: {
            UPDATE: 'updating',
          },
        },
      },
    },
    notifications: {
      id: 'notifications',
      initial: 'loading',
      states: {
        loading: {
          invoke: {
            src: 'getDeviceNotifications',
          },
          on: {
            SUCCESS: {
              target: '#notifications.list',
              actions: 'assignDeviceNotifications',
            },
            FAILURE: 'list',
          },
        },
        updating: {
          invoke: {
            src: 'updateNotifications',
          },
          on: {
            SUCCESS: 'list',
            FAILURE: 'list',
          },
        },
        list: {
          on: {
            UPDATE: 'updating',
          },
        },
      },
    },
    help: {},
    exports: {},
    encloMode: {
      on: {
        CLICK_DRAWING: {
          target: ['#addenclo.drawing', '#modals.closed'],
        },
      },
    },
    encloDelete: {
      on: {
        YES: {
          target: ['#enclosure.deleting', '#modals.closed'],
        },
        NO: {
          target: ['#enclosure.list', '#modals.closed'],
        },
      },
    },
    encloSetting: {
      on: {
        ADDING_ENCLO: {
          target: ['#enclosure.addEnclo.addingEnclo', '#modals.closed'],
        },
        CLOSE: {
          target: ['#enclosure.addEnclo.drawing', '#modals.closed'],
        },
      },
    },
  },
  on: {
    CLOSE_MODAL: {
      target: '.closed',
      // in: ['.calendar', '.share', '.settings', '.notifications', '.help', '.exports'],
    },
  },
};

const addEnclo = {
  id: 'addenclo',
  initial: 'manual',
  states: {
    manual: {},
    drawing: {
      on: {
        COMPLETE: {
          target: ['setting', '#modals.encloSetting'],
          actions: 'addPendingEncloPath',
        },
      },
    },
    setting: {},
    addingEnclo: {
      invoke: {
        id: 'addEnclo',
        src: 'addEnclo',
      },
      on: {
        SUCCESS: '#enclosure.loading',
        FAILURE: '#enclosure.list',
      },
    },
  },
};
const enclosVirtuel = {
  id: 'enclosure',
  initial: 'loading',
  states: {
    loading: {
      invoke: {
        id: 'getEnclosure',
        src: 'getEnclosure',
      },
      on: {
        SUCCESS: {
          target: 'list',
          actions: 'assignEnclosure',
        },
      },
      // FAILURE: {
      //   target: 'requestErr',
      //   actions: ['assignErrors']
      // }
    },
    list: {
      on: {
        ADD_ENCLO: {
          target: ['addEnclo', '#modals.encloMode'],
        },
        DELETE_ENCLO: {
          target: '#modals.encloDelete',
          actions: 'deletingEnclosure',
        },
      },
    },
    deleting: {
      invoke: {
        id: 'deleteEnclo',
        src: 'deleteEnclo',
      },
      on: {
        SUCCESS: '#enclosure.loading',
        FAILURE: '#enclosure.list',
      },
    },
    addEnclo,
  },
  on: {
    CLOSE_ENCLOS: '#map.deviceDetail',
    CLICK_ENCLOS_VIRTUEL: '#map.deviceDetail',
  },
};

const history = {
  id: 'history',
  initial: 'loadingHistory',
  states: {
    loadingHistory: {
      invoke: {
        src: 'getDeviceHistory',
      },
      on: {
        SUCCESS: {
          target: 'showingHistory',
          actions: 'assignDeviceHistory',
        },
      },
    },
    showingHistory: {},
  },
};

const deviceHistory = {
  type: 'parallel',
  states: {
    history,
    dateFilter,
    pointInfo,
  },
};

const deviceMode = {
  id: 'deviceMode',
  initial: 'deviceHistory',
  states: {
    deviceHistory,
    enclosVirtuel,
  },
};

const deviceDetail = {
  type: 'parallel',
  initial: 'deviceHistory',
  states: {
    deviceMode,
    modals,
  },
  on: {
    CLICK_ENCLOS_VIRTUEL: {
      target: ['.deviceMode.enclosVirtuel', '.modals.closed'],
    },
    CLICK_SHARE: '.modals.share',
    CLICK_SETTINGS: '.modals.settings',
    CLICK_NOTIFICATIONS: '.modals.notifications',
    CLICK_HELP: '.modals.help',
    CLICK_EXPORT: '.modals.exports',
    CLICK_DEVICE: {
      target: '#history.loadingHistory',
      actions: 'changeCurrentDevice',
    },
    CLICK_MAP: {
      target: '#map.deviceList',
      actions: 'unselectCurrentDevice',
    },
  },
};

export const mapMachineConfig = {
  id: 'map',
  initial: 'deviceList',
  context: {
    selected: null,
    dateRange: null,
    enclos: null,
    pendingEncloPath: null,
    deviceNotifications: null,
  },
  states: {
    deviceList: {
      on: {
        CLICK_DEVICE: {
          target: 'deviceDetail',
          actions: 'changeCurrentDevice',
        },
      },
    },
    deviceDetail,
  },
};
