import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil, share } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FusePerfectScrollbarDirective } from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { APIService } from 'app/services/api.service';
import { CognitoUtil } from 'app/services/cognito.service';

import { User } from 'app/classes/user';
import { DeviceService } from 'app/services/device.service';
import { MapMachine } from 'app/main/secure/main/+xstate/service';

@Component({
  selector: 'navbar-vertical-style-2',
  templateUrl: './style-2.component.html',
  styleUrls: ['./style-2.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarVerticalStyle2Component implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  sidebarIsFolded = false;

  // devices: Devices;
  cognitoUser: any;
  user: User;
  device: any;
  devices$: any;
  ownDevices$: any;
  shareDevices$: any;

  message: any;

  // Private
  private fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private unsubscribeAll: Subject<any>;

  constructor(
    private fuseConfigService: FuseConfigService,
    private fuseNavigationService: FuseNavigationService,
    private fuseSidebarService: FuseSidebarService,
    private router: Router,
    private apiService: APIService,
    private cognitoUtil: CognitoUtil,
    private deviceService: DeviceService,
    private xstate: MapMachine,
  ) {
    // Set the private defaults
    this.unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Directive
  @ViewChild(FusePerfectScrollbarDirective, { static: false })
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this.fusePerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this.fuseNavigationService.onItemCollapseToggled.pipe(delay(500), takeUntil(this.unsubscribeAll)).subscribe(() => {
      this.fusePerfectScrollbar.update();
    });

    // Scroll to the active item position
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        take(1),
      )
      .subscribe(() => {
        setTimeout(() => {
          const activeNavItem: any = document.querySelector('navbar .nav-link.active');

          if (activeNavItem) {
            const activeItemOffsetTop = activeNavItem.offsetTop;
            const activeItemOffsetParentTop = activeNavItem.offsetParent.offsetTop;
            const scrollDistance = activeItemOffsetTop - activeItemOffsetParentTop - 48 * 3;

            this.fusePerfectScrollbar.scrollToTop(scrollDistance);
          }
        });
      });
  }

  ngOnInit(): void {
    // passes the current device to main component
    this.deviceService.currentMessage.subscribe(message => (this.message = message));

    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        takeUntil(this.unsubscribeAll),
      )
      .subscribe(() => {
        if (this.fuseSidebarService.getSidebar('navbar')) {
          this.fuseSidebarService.getSidebar('navbar').close();
        }
      });

    // Get current navigation
    this.fuseNavigationService.onNavigationChanged
      .pipe(
        filter(value => value !== null),
        takeUntil(this.unsubscribeAll),
      )
      .subscribe(() => {
        this.navigation = this.fuseNavigationService.getCurrentNavigation();
      });

    // Subscribe to the config changes
    this.fuseConfigService.config.pipe(takeUntil(this.unsubscribeAll)).subscribe(config => {
      this.fuseConfig = config;
    });

    this.getDevicesForUser();
  }

  newDevice() {
    this.deviceService.changeMessage(this.device);
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar opened status
   */
  toggleSidebarOpened(): void {
    this.fuseSidebarService.getSidebar('navbar').toggleOpen();
    this.sidebarIsFolded = false;
  }

  /**
   * Toggle sidebar folded status
   */
  toggleSidebarFolded(): void {
    this.fuseSidebarService.getSidebar('navbar').toggleFold();
    if (this.sidebarIsFolded) {
      this.sidebarIsFolded = false;
    } else {
      this.sidebarIsFolded = true;
    }
  }

  getDevicesForUser() {
    this.cognitoUser = this.cognitoUtil.getCurrentUser();

    this.devices$ = this.apiService.getDevicesFromUser(this.cognitoUser.username).pipe(share());

    this.ownDevices$ = this.devices$.map(devices => devices.items.filter(device => device.owner_id === this.cognitoUser.username));
    this.shareDevices$ = this.devices$.map(devices => devices.items.filter(device => device.owner_id !== this.cognitoUser.username));

    this.devices$.subscribe(devices => {
      devices = devices.items.filter(point => point.last_position).map(point => point.last_position);
      this.zoomToObject(devices);
    });
  }

  zoomToObject(points) {
    // @ts-ignore
    if (window.google) {
      // @ts-ignore
      const bounds = new google.maps.LatLngBounds();
      for (let n = 0; n < points.length; n++) {
        if (points[n].lat && points[n].lng) {
          bounds.extend(points[n]);
        }
      }
      // @ts-ignore
      window.map.fitBounds(bounds);
    }
  }

  getDevice(id) {
    this.xstate.send('CLICK_MAP');

    console.log(id);
    this.cognitoUser = this.cognitoUtil.getCurrentUser();
    this.router.navigate(['/main']);
    return this.apiService.getDevice(id, this.cognitoUser.username).subscribe(
      device => {
        this.device = device;

        const defaultLastPosition = {
          lng: 2.3786334,
          lat: 48.8849525,
        };
        this.device.last_position = this.device.last_position || defaultLastPosition;
        this.deviceService.changeMessage(this.device);
        setInterval(() => {
          console.log('device', this.device);
        }, 10000);
      },
      err => {
        console.log(err);
      },
    );
  }
}
