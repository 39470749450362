import { assign } from 'xstate';

export const changeCurrentDevice = assign({
  selected: (context, event) => {
    // @ts-ignore
    return event.selected;
  },
});
export const assignDeviceHistory = assign({
  deviceHistory: (context, event) => {
    // @ts-ignore
    return event.history;
  },
});
export const assignDeviceSettings = assign({
  deviceSettings: (context, event) => {
    return event;
  },
});
export const assignDeviceNotifications = assign({
  deviceNotifications: (context, event) => {
    console.log('events', event);
    // @ts-ignore
    return event.notifications;
  },
});

export const changeDateRange = assign({
  // @ts-ignore
  dateRange: (context, event) => event.dateRange,
});
export const unsetDateRange = assign({
  // @ts-ignore
  dateRange: () => {
    return {
      begin: 0,
      end: 0,
    };
  },
});

export const assignEnclosure = assign({
  enclos: (context, event) => {
    // @ts-ignore
    return event.enclosure;
  },
});
export const assignEnclosureShares = assign({
  shares: (context, event) => {
    // @ts-ignore
    return event.enclosure;
  },
});
export const deletingEnclosure = assign({
  deletingEnclo: (context, event) => {
    // @ts-ignore
    return event.enclo;
  },
});
export const addPendingEncloPath = assign({
  pendingEncloPath: (context, event) => {
    // @ts-ignore
    return event.path;
  },
});

export const unselectCurrentDevice = assign({
  selected: null,
});
