import { Component, ElementRef, Input, Renderer2, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavbarComponent {
  // Private
  variantNb: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
    // Set the private defaults
    this.variantNb = 'vertical-style-1';
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Variant
   */
  get variant(): string {
    return this.variantNb;
  }

  @Input()
  set variant(value: string) {
    // Remove the old class name
    this.renderer.removeClass(this.elementRef.nativeElement, this.variant);

    // Store the variant value
    this.variantNb = value;

    // Add the new class name
    this.renderer.addClass(this.elementRef.nativeElement, value);
  }
}
