export const locale = {
  lang: "es",
  data: {
    LOGIN: {
      LOGIN: "Conectarse",
      PASSWORD: "Contraseña",
      FORGOTTEN: "Contraseña olvidada",
      OR: "O",
      NO_ACCOUNT: "No tienes cuenta aún?",
      REINITIALIZE: "Reinicialisar su contraseña",
      SEND_EMAIL: "Enviar enlace",
      BACK: "Volver al login",
      VISIT: "Pincha aquí",
      CLOSE: "Cerrar"
    },
    MENU: {
      FULL_SCREEN: "Pantalla completa",
      INFO: "Informaciones",
      NOTIF: "Notificaciones",
      PARAM: "Ajustes",
      PAYMENT: "Pagamiento",
      LOGOUT: "Desconectarse"
    },
    MAIN: {
      HELLO: "Hola Dünya!"
    },
    PROFILE: {
      TITLE_USER: "Informaciones de usuario",
      FIRSTNAME: "Nombre",
      LASTNAME: "Apellidos",
      BIRTHDATE: "Fecha de nacimiento",
      COUNTRY: "País",
      PHONE: "Telefono",
      LANGUAGE: "Idioma",
      TITLE_DELIVER: "Informaciones de entrega",
      TITLE_BILL: "Informaciones de facturación",
      ACTION: "Guardar",
      CHOSE_PIC: "Buscar foto",
      UPLOAD: "Cargar",
      PIC_LABEL: "Imagen de perfil"
    },
    NOTIFICATIONS: {
      TODAY: "Hoy",
      YESTERDAY: "Ayer",
      BEFORE: "Antes"
    },
    SETTINGS: {
      ALERT_NOTIF_TITLE: "Alertas de notificaciones",
      ALERT_NOTIF_TXT:
        "Activar toutes les notifications d’alerte importantes que vos Beepings peuvent vous envoyer. (detection de mouvement, batterie, enclos…)",
      GENERAL_NOTIF_TITLE: "Notificaciones generales",
      GENERAL_NOTIF_TXT:
        "Las notificaciones générales vous informent sur l’état de votre balise, ses niveaux de signaux…",
      UPDATE_TITLE: "Actualizaciones",
      UPDATE_TXT:
        "Vous permet d’être alerté lorsque que des mises à jour sont disponibles.",
      COMMUNITY_TITLE: "Comunidad",
      COMMUNITY_TXT:
        "Participer à la communauté Beepings en cas d’alerte de vol, ou tout autre etat critique déclaré par un autre membre de la communauté.",
      TECHNICAL_INFO_TITLE: "Informaciones tecnicas",
      TECHNICAL_INFO_TXT:
        "Restez informé des interventions ou évolution technique majeur sur les solutions Beepings.",
      NEWS_TITLE: "Noticias Beepings",
      NEWS_TXT:
        "Recevoir des nouvelles clés sur l’actualité de Beepings, les evenements, les sorties produits.",
      COMMERCIAL_TITLE: "Ofertas comercial",
      COMMERCIAL_TXT:
        "Recevez en exclusivité les offres promotionnelles Beepings."
    },
    PAYMENT: {
      TITLE: "Ninguna información disponible"
    }
  }
};
