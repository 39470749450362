import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, SatDatepickerModule, SatNativeDateModule } from 'saturn-datepicker';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';
import { AgmDrawingModule } from '@agm/drawing';

import { MatIconModule, MatButtonModule, MatCheckboxModule, MatInputModule, MatSlideToggleModule, MatListModule } from '@angular/material';

import { DeviceControlsComponent } from './device-controls/device-controls.component';
import { MainComponent } from './main.component';
import { TimeFilterComponent } from './time-filter/time-filter.component';
import { ModalsComponent } from './modals/modals.component';
import { CalendarComponent } from './modals/calendar/calendar.component';
import { NotificationsComponent } from './modals/notifications/notifications.component';
import { SettingsComponent } from './modals/settings/settings.component';
import { ShareComponent } from './modals/share/share.component';
import { HelpComponent } from './modals/help/help.component';
import { ExportComponent } from './modals/export/export.component';
import { MapMachine } from './+xstate/service';
import { DeviceMenuComponent } from './device-controls/device-menu/device-menu.component';
import { EnclosListComponent } from './device-controls/enclos-list/enclos-list.component';
import { EncloModeComponent } from './modals/enclo-mode/enclo-mode.component';
import { EncloSettingComponent } from './modals/enclo-setting/enclo-setting.component';
import { FormsModule } from '@angular/forms';
import { EncloDeleteComponent } from './modals/enclo-delete/enclo-delete.component';

@NgModule({
  declarations: [
    MainComponent,
    DeviceControlsComponent,
    TimeFilterComponent,
    ModalsComponent,
    CalendarComponent,
    NotificationsComponent,
    SettingsComponent,
    ShareComponent,
    HelpComponent,
    ExportComponent,
    DeviceMenuComponent,
    EnclosListComponent,
    EncloModeComponent,
    EncloSettingComponent,
    EncloDeleteComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    MatListModule,
    SatDatepickerModule,
    SatNativeDateModule,
    AgmOverlays,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAW3huQ2yPTeR5f5gqNRfUG49EKFlpsSb4',
      libraries: ['drawing'],
    }),
    AgmDrawingModule,
  ],
  providers: [
    MapMachine,
    { provide: MAT_DATE_LOCALE, useValue: 'fr-FR' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
  ],
})
export class MainModule {}
