import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './main/public/authentication/login/login.component';
import { ForgotPasswordComponent } from './main/public/authentication/forgot-password/forgot-password.component';
import { Error404Component } from './main/public/errors/404/error-404.component';
import { MainComponent } from './main/secure/main/main.component';
import { AuthGuard } from './services/auth.guard';
import { ProfileComponent } from './main/secure/profile/profile.component';
import { MailConfirmComponent } from './main/public/authentication/mail-confirm/mail-confirm.component';
import { ParametersComponent } from './main/secure/parameters/parameters.component';
import { NotificationsComponent } from './main/secure/notifications/notifications.component';
import { PaymentComponent } from './main/secure/payment/payment.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'mail-confirm', component: MailConfirmComponent },
  { path: 'main', component: MainComponent, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
  {
    path: 'settings',
    component: ParametersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    canActivate: [AuthGuard]
  },
  { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', component: Error404Component }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true } // <-- debugging purposes only
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
