import { mapMachineConfig } from './config';
import * as actions from './actions';

import { fromEventPattern, of } from 'rxjs';

// @ts-ignore
import { interpret, Machine, MachineOptions, State, assign, EventObject } from 'xstate';
import { Injectable } from '@angular/core';
// import { AuthSchema, AuthContext } from './auth-machine.schema';
import { map, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { APIService } from 'app/services/api.service';
import {
  GetEnclosureFail,
  GetEnclosureSuccess,
  AddEnclosureSuccess,
  AddEnclosureFail,
  DeleteEnclosureSuccess,
  DeleteEnclosureFail,
  GetEnclosureSharingSuccess,
  GetEnclosureSharingFail,
  DeleteEnclosureSharingSuccess,
  DeleteEnclosureSharingFail,
  AddEnclosureSharingSuccess,
  AddEnclosureSharingFail,
  GetDeviceSettingsSuccess,
  GetDeviceSettingsFail,
  UpdateDeviceSettingsSuccess,
  UpdateDeviceSettingsFail,
  GetDeviceHistorySuccess,
  GetDeviceHistoryFail,
  GetDeviceNotificationsSuccess,
  GetDeviceNotificationsFail,
} from './event';
// import { LoginSuccess, AuthEvent, LoginFail } from './auth-machine.events';

@Injectable()
export class MapMachine {
  constructor(private router: Router, private api: APIService) {}
  mapMachineOptions = {
    services: {
      getEnclosure: ctx =>
        this.api.getEnclosure(ctx.selected.id).pipe(
          map(enclo => new GetEnclosureSuccess(enclo)),
          catchError(result => of(new GetEnclosureFail(result.error.errors))),
        ),
      getEnclosureSharing: ctx =>
        this.api.getEnclosureSharing(ctx).pipe(
          map(enclo => new GetEnclosureSharingSuccess(enclo)),
          catchError(result => of(new GetEnclosureSharingFail(result.error.errors))),
        ),
      addEnclosureSharing: (ctx, data) =>
        this.api.addEnclosureSharing(ctx, data).pipe(
          map(enclo => new AddEnclosureSharingSuccess(enclo)),
          catchError(result => of(new AddEnclosureSharingFail(result.error.errors))),
        ),
      deleteEnclosureSharing: (ctx, data) =>
        this.api.deleteEnclosureSharing(ctx, data).pipe(
          map(enclo => new DeleteEnclosureSharingSuccess(enclo)),
          catchError(result => of(new DeleteEnclosureSharingFail(result.error.errors))),
        ),
      addEnclo: (ctx, data) =>
        this.api.addEnclosure(ctx, data).pipe(
          map(enclo => new AddEnclosureSuccess(enclo)),
          catchError(result => of(new AddEnclosureFail(result))),
        ),
      deleteEnclo: ctx =>
        this.api.deleteEnclosure(ctx).pipe(
          map(enclo => new DeleteEnclosureSuccess(enclo)),
          catchError(result => of(new DeleteEnclosureFail(result))),
        ),
      getDeviceSettings: ctx =>
        this.api.getDeviceSettings(ctx).pipe(
          map(enclo => new GetDeviceSettingsSuccess(enclo)),
          catchError(result => of(new GetDeviceSettingsFail(result))),
        ),
      getDeviceHistory: (ctx, data) =>
        this.api.getDeviceHistory(ctx, data).pipe(
          map(enclo => new GetDeviceHistorySuccess(enclo)),
          catchError(result => of(new GetDeviceHistoryFail(result))),
        ),
      updateDeviceSettings: (ctx, data) =>
        this.api.updateDeviceSettings(ctx, data).pipe(
          map(enclo => new UpdateDeviceSettingsSuccess(enclo)),
          catchError(result => of(new UpdateDeviceSettingsFail(result))),
        ),
      getDeviceNotifications: (ctx, data) =>
        this.api.getDeviceNotifications(ctx, data).pipe(
          map(enclo => new GetDeviceNotificationsSuccess(enclo)),
          catchError(result => of(new GetDeviceNotificationsFail(result))),
        ),
      updateNotifications: (ctx, data) =>
        this.api.updateNotifications(ctx, data).pipe(
          map(enclo => new UpdateDeviceSettingsSuccess(enclo)),
          catchError(result => of(new UpdateDeviceSettingsFail(result))),
        ),
    },
    guards: {
      // isLoggedOut: () => !localStorage.getItem('jwtToken'),
    },
    actions: {
      ...actions,
      // assignUser: assign<AuthContext, LoginSuccess>((_, event) => ({
      //   user: event.userInfo,
      // })),
      // assignErrors: assign<AuthContext, LoginFail>((_, event) => ({
      //   errors: Object.keys(event.errors || {}).map(key => `${key} ${event.errors[key]}`),
      // })),
      // loginSuccess: (ctx, _) => {
      //   localStorage.setItem('jwtToken', ctx.user.token);
      //   this.router.navigateByUrl('');
      // },
    },
  };

  //  @ts-ignore
  private mapMachineWithOptions = Machine(mapMachineConfig).withConfig(this.mapMachineOptions);
  private service = interpret(this.mapMachineWithOptions, { devTools: true }).start();

  state$ = fromEventPattern(
    handler => {
      return this.service.onTransition(handler);
    },
    // (_, service) => service.stop(),
  ).pipe(map(([state, _]) => state));

  // CONTEXT SELECTORS -
  selectors = {
    modal: filter => {
      return {
        deviceDetail: {
          modals: filter,
        },
      };
    },
    date: filter => {
      return {
        deviceDetail: {
          deviceMode: {
            deviceHistory: {
              dateFilter: filter,
            },
          },
        },
      };
    },
    enclos: filter => {
      return {
        deviceDetail: {
          deviceMode: {
            enclosVirtuel: filter,
          },
        },
      };
    },
  };

  context$ = this.state$.map(state => state.context);
  enclos$ = this.context$.map(ctx => ctx.enclos);
  selected$ = this.context$.map(ctx => ctx.selected);
  shares$ = this.context$.map(ctx => ctx.shares);
  deviceSettings$ = this.context$.map(ctx => ctx.deviceSettings);
  deviceNotifications$ = this.context$.map(ctx => ctx.deviceNotifications);
  deviceHistory$ = this.context$.map(ctx => ctx.deviceHistory);

  value$ = this.state$.map(state => state.value);

  deviceDetail$ = this.state$.pipe(map(state => state.matches('deviceDetail')));

  match = statSelector => this.state$.map(state => state.matches(statSelector));

  send(eventName: string, data?) {
    this.service.send(eventName, data);
  }
}
