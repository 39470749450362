import { Component, OnInit } from '@angular/core';
import { MapMachine } from '../../+xstate/service';

@Component({
  selector: 'app-enclos-list',
  templateUrl: './enclos-list.component.html',
  styleUrls: ['./enclos-list.component.scss'],
})
export class EnclosListComponent implements OnInit {
  constructor(private xstate: MapMachine) {}

  ngOnInit() {}

  zoomToObject(enclo) {
    // @ts-ignore

    const bounds = new google.maps.LatLngBounds();
    const points = enclo.shape.points;
    for (let n = 0; n < points.length; n++) {
      bounds.extend(points[n]);
    }

    console.log('bounds', bounds);

    // @ts-ignore
    window.map.fitBounds(bounds);
  }
}
