import { Component, OnInit, Input } from '@angular/core';
import { MapMachine } from '../../+xstate/service';

@Component({
  selector: 'app-device-menu',
  templateUrl: './device-menu.component.html',
  styleUrls: ['./device-menu.component.scss'],
})
export class DeviceMenuComponent implements OnInit {
  @Input() device;

  constructor(private xstate: MapMachine) {}

  ngOnInit() {}
}
