import { Component, OnInit, ViewEncapsulation, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';

import { Router } from '@angular/router';
import { UserLoginService } from '../../../../services/user-login.service';
import { ChallengeParameters, CognitoCallback, LoggedInCallback } from '../../../../services/cognito.service';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';

export interface DialogData {
  text: string;
  title: string;
}

@Component({
  selector: 'dialog-elements-example-dialog',
  templateUrl: 'dialog-elements-example-dialog.html',
  styleUrls: ['./login.component.scss'],
  animations: fuseAnimations,
})
export class DialogElementsExampleDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class LoginComponent implements OnInit, CognitoCallback, LoggedInCallback {
  loginForm: FormGroup;

  email: string;
  password: string;
  errorMessage: string;
  mfaStep = false;
  mfaData = {
    destination: '',
    callback: null,
  };

  passString: any;
  showPass = false;

  constructor(
    private fuseConfigService: FuseConfigService,
    private formBuilder: FormBuilder,
    public router: Router,
    public userService: UserLoginService,
    public dialog: MatDialog,
    private fuseSplashScreenService: FuseSplashScreenService,
  ) {
    console.log('LoginComponent constructor');

    // Configure the layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });

    this.errorMessage = null;
    console.log('Checking if the user is already authenticated. If so, then redirect to the secure site');
    this.userService.isAuthenticated(this);
    console.log(this.showPass);
  }

  onLogin() {
    if (this.email == null || this.password == null) {
      this.errorMessage = 'Merci de remplir tous les champs.';
      return;
    }
    this.errorMessage = null;
    this.userService.authenticate(this.email, this.password, this);
    // loading screen
    this.fuseSplashScreenService.show();
  }

  openDialogNoAccount() {
    if (navigator.language === 'es-ES') {
      this.dialog.open(DialogElementsExampleDialogComponent, {
        data: {
          title: 'Crear cuenta',
          text: 'Para crear una cuenta Beepings, descarga la aplicación desde tu smartphone.',
        },
      });
    } else if (navigator.language === 'fr-FR') {
      this.dialog.open(DialogElementsExampleDialogComponent, {
        data: {
          title: 'Créer un compte',
          text: "Pour creer un compte Beepings, telecharger l'application depuis un smartphone.",
        },
      });
    } else {
      this.dialog.open(DialogElementsExampleDialogComponent, {
        data: {
          title: 'Create an account',
          text: 'To create a Beepings account, download the app from your smartphone.',
        },
      });
    }
  }

  openDialog() {
    if (navigator.language === 'es-ES') {
      this.dialog.open(DialogElementsExampleDialogComponent, {
        data: {
          title: 'Usuario desconocido',
          text:
            'Lo sentimos pero no encontramos su nombre en nuestra base de usuarios. Tiene que comprar un producto nuestro primero, y descargar nuestra aplicación movil.',
        },
      });
    } else if (navigator.language === 'fr-FR') {
      this.dialog.open(DialogElementsExampleDialogComponent, {
        data: {
          title: 'Utilisateur inconnu',
          text:
            "Desolé mais nous ne reconnaissons pas votre nom d'utilisateur. Vous devez d'abord acheter un de nos produits et telecharger notre application mobile.",
        },
      });
    } else {
      this.dialog.open(DialogElementsExampleDialogComponent, {
        data: {
          title: 'Unknown user',
          text: "Sorry but we can't find your username. You must first buy one of our products and download our mobile app.",
        },
      });
    }
  }

  cognitoCallback(message: string, result: any) {
    if (message != null) {
      // error
      this.errorMessage = message;
      console.log('result: ' + this.errorMessage);

      if (this.errorMessage === 'User is not confirmed.') {
        console.log('redirecting');
        this.router.navigate(['/mail-confirm', this.email]);
      } else if (this.errorMessage === 'User needs to set password.') {
        console.log('redirecting to set new password');
        this.router.navigate(['/forgot-password']);
      } else if (this.errorMessage === 'User does not exist.' || this.errorMessage === '400') {
        if (navigator.language === 'es-ES') {
          this.errorMessage = 'Este usuario no existe.';
        } else if (navigator.language === 'fr-FR') {
          this.errorMessage = "Cet utilisateur n'existe pas. Veuillez verifier les informations fournies.";
        } else {
          this.errorMessage = "This user doesn't exist. Please check your information.";
        }

        console.log('modal');
        this.openDialog();
      } else if (this.errorMessage === 'Incorrect username or password.') {
        if (navigator.language === 'es-ES') {
          this.errorMessage = 'Usuario o contraseña incorrecta.';
        } else if (navigator.language === 'fr-FR') {
          this.errorMessage = 'Utilisateur ou mot de passe incorrect.';
        } else {
          this.errorMessage = 'Incorrect username or password.';
        }
      }
      this.fuseSplashScreenService.hide();
    } else {
      // success
      this.fuseSplashScreenService.hide();
      this.router.navigate(['/main']);
    }
  }

  handleMFAStep(challengeName: string, challengeParameters: ChallengeParameters, callback: (confirmationCode: string) => any): void {
    this.mfaStep = true;
    this.mfaData.destination = challengeParameters.CODE_DELIVERY_DESTINATION;
    this.mfaData.callback = (code: string) => {
      if (code == null || code.length === 0) {
        this.errorMessage = 'Un code est requis.';
        return;
      }
      this.errorMessage = null;
      callback(code);
    };
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.router.navigate(['/main']);
    }
  }

  cancelMFA(): boolean {
    this.mfaStep = false;
    return false; // necessary to prevent href navigation
  }

  togglePassword() {
    console.log('First round', this.showPass);
    this.passString = document.getElementById('signupPassword');
    this.showPass = !this.showPass;
    if (this.showPass) {
      this.passString.setAttribute('type', 'text');
    } else {
      this.passString.setAttribute('type', 'password');
    }
  }
}
