export const locale = {
  lang: 'es',
  data: {
    NAV: {
      DEVICES: 'Aparatos',
      MAIN: {
        TITLE: 'Zen mio',
        BADGE: '25'
      }
    }
  }
};
