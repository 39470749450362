import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { User, UserProfile } from 'app/classes/user';
import { CognitoUtil } from './cognito.service';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { tap } from 'rxjs/internal/operators/tap';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class APIService {
  constructor(private httpClient: HttpClient, private cognitoUtil: CognitoUtil) {}

  token = this.getIdToken();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.token,
      'x-api-key': environment.apiKey,
    }),
  };

  headers = {
    'X-Requested-With': '*',
    'Access-Control-Allow-Headers': 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,x-requested-with, X-Amz-Security-Token',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST,GET,OPTIONS',
    'Content-Type': 'application/json',
    Authorization: this.token,
    'x-api-key': environment.apiKey,
  };

  get userId() {
    const user: any = this.cognitoUtil.getCurrentUser();
    return user.username;
  }

  getIdToken() {
    const key = Object.keys(localStorage).find(key => /\.idToken$/.test(key));
    return localStorage.getItem(`${key}`);
  }

  public getUser(id: string) {
    return this.httpClient.get<User>(`${environment.endpoint}/users/${id}`, this.httpOptions);
  }

  public updateUserProfile(id: string, data) {
    console.log('this is the dtata we send', data);
    return this.httpClient.put(`${environment.endpoint}/users/${id}`, data, this.httpOptions).pipe(
      tap(updatedUser => console.log(`updated user`, updatedUser)),
      catchError(this.handleError<any>('updateUserProfile')),
    );
  }

  public sendConfirmationEmail(id: string) {
    return this.httpClient.get(`${environment.endpoint}/users/${id}/confirmation/send`, this.httpOptions);
  }

  public getDevicesFromUser(id: string) {
    return this.httpClient.get(`${environment.endpoint}/users/${id}/devices`, this.httpOptions);
  }

  public getDevice(deviceId: string, userId: string) {
    return this.httpClient.get(`${environment.endpoint}/users/${userId}/devices/${deviceId}`, this.httpOptions);
  }

  public sendProfilePic(id: string, img) {
    console.log('this is the dtata we send', img);
    return this.httpClient.post(`${environment.endpoint}/users/${id}/picture`, JSON.stringify({ file_base64: img }), this.httpOptions).pipe(
      tap(updatedUserImg => console.log(`updated user img`, updatedUserImg)),
      catchError(this.handleError<any>('updateUserProfileImg')),
    );
  }

  public getDeviceNotifications(ctx, data) {
    return this.httpClient.get(
      `${environment.endpoint}/users/${this.userId}/devices/${ctx.selected.id}/notifications/history`,
      this.httpOptions,
    );
  }

  public updateNotifications(ctx, data) {
    return this.httpClient.get(
      `${environment.endpoint}/users/${this.userId}/devices/${ctx.selected.id}/notifications/history`,
      this.httpOptions,
    );
  }

  public getNotifications(id: string) {
    return this.httpClient.get(`${environment.endpoint}/users/${id}/devices/notifications/history`, this.httpOptions);
  }

  public getSettings(id: string) {
    return this.httpClient.get(`${environment.endpoint}/users/${id}/notifications`, this.httpOptions);
  }

  public postSettings(id: string, data) {
    // return this.httpClient
    //     .get(`${environment.endpoint}/users/${id}/notifications`, this.httpOptions);
    console.log('this is the dtata we send', data);
    // data.devices_notifications_alerts = true;

    return this.httpClient.put(`${environment.endpoint}/users/${id}/notifications`, JSON.stringify(data), this.httpOptions).pipe(
      tap(updatedSettings => console.log(`updated settings`, updatedSettings)),
      catchError(this.handleError<any>('updateUserProfile')),
    );
  }

  public getEnclosure(deviceId: number) {
    return this.httpClient.get(`${environment.endpoint}/users/${this.userId}/devices/${deviceId}/perimeters`, this.httpOptions);
  }
  public getEnclosureSharing(ctx) {
    return this.httpClient.get(`${environment.endpoint}/users/${this.userId}/devices/${ctx.selected.id}/shares`, this.httpOptions);
  }
  public getDeviceSettings(ctx) {
    return this.httpClient.get(
      `${environment.endpoint}/users/${this.userId}/devices/${ctx.selected.id}/notifications/settings`,
      this.httpOptions,
    );
  }

  public getDeviceHistory(ctx, data) {
    let dateRange = {
      begin: 0,
      end: 0,
    };
    if (ctx.dateRange) {
      dateRange = ctx.dateRange;
    }

    let params = new HttpParams();
    params = params.append('date-from', (dateRange.begin / 1000).toString());
    params = params.append('date-to', (dateRange.end / 1000).toString());

    return this.httpClient.get(`${environment.endpoint}/users/${this.userId}/devices/${ctx.selected.id}/messages`, {
      ...this.httpOptions,
      params,
    });
  }

  public updateDeviceSettings(ctx, data) {
    console.log('data', data);

    return this.httpClient.put(
      `${environment.endpoint}/users/${this.userId}/devices/${ctx.selected.id}/notifications/settings`,
      JSON.stringify(data.settings),
      this.httpOptions,
    );
  }
  public addEnclosureSharing(ctx, data) {
    return this.httpClient.post(
      `${environment.endpoint}/users/${this.userId}/devices/${ctx.selected.id}/shares`,
      JSON.stringify({
        email: data.email,
      }),
      this.httpOptions,
    );
  }
  public deleteEnclosureSharing(ctx, data) {
    return this.httpClient.delete(
      `${environment.endpoint}/users/${this.userId}/devices/${ctx.selected.id}/shares/${data.user.user_id}`,
      this.httpOptions,
    );
  }
  public addEnclosure(ctx, data) {
    return this.httpClient.post(
      `${environment.endpoint}/users/${this.userId}/devices/${ctx.selected.id}/perimeters`,
      JSON.stringify({
        name: data.name,
        alert_in: data.in,
        alert_out: data.out,
        shape: {
          points: ctx.pendingEncloPath,
        },
      }),
      this.httpOptions,
    );
  }

  public deleteEnclosure(ctx) {
    return this.httpClient.delete(
      `${environment.endpoint}/users/${this.userId}/devices/${ctx.selected.id}/perimeters/${ctx.deletingEnclo.id}`,
      this.httpOptions,
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
