import { NgModule } from '@angular/core';
import {
  MatDividerModule,
  MatListModule,
  MatSlideToggleModule,
  MatCardModule
} from '@angular/material';

import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarSecondaryComponent } from 'app/layout/components/navbar-secondary/navbar-secondary.component';

@NgModule({
  declarations: [],
  imports: [
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    MatCardModule,
    FuseSharedModule
  ],
  exports: []
})
export class NavbarSecondaryModule {}
