import { Component, OnInit } from '@angular/core';
import { MapMachine } from '../../+xstate/service';

@Component({
  selector: 'app-enclo-mode',
  templateUrl: './enclo-mode.component.html',
  styleUrls: ['./enclo-mode.component.scss'],
})
export class EncloModeComponent implements OnInit {
  constructor(private xstate: MapMachine) {}

  ngOnInit() {}
}
