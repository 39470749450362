export const locale = {
  lang: "en",
  data: {
    LOGIN: {
      LOGIN: "Login",
      PASSWORD: "Password",
      FORGOTTEN: "Forgotten password",
      OR: "Or",
      NO_ACCOUNT: "No account yet?",
      REINITIALIZE: "Reinitialize your password",
      SEND_EMAIL: "Send link",
      BACK: "Back to login",
      VISIT: "Click here",
      CLOSE: "Close"
    },
    MENU: {
      FULL_SCREEN: "Full screen",
      INFO: "Information",
      NOTIF: "Notifications",
      PARAM: "Settings",
      PAYMENT: "Payment",
      LOGOUT: "Logout"
    },
    MAIN: {
      HELLO: "Hello, World!"
    },
    PROFILE: {
      TITLE_USER: "User information",
      FIRSTNAME: "Firstname",
      LASTNAME: "Lastname",
      BIRTHDATE: "Birhdate",
      COUNTRY: "Country",
      PHONE: "Phone",
      LANGUAGE: "Language",
      TITLE_DELIVER: "Delivery information",
      TITLE_BILL: "Billing information",
      ACTION: "Save",
      CHOSE_PIC: "Browse image",
      UPLOAD: "Upload",
      PIC_LABEL: "Profile picture"
    },
    NOTIFICATIONS: {
      TODAY: "Today",
      YESTERDAY: "Yesterday",
      BEFORE: "Days before"
    },
    SETTINGS: {
      ALERT_NOTIF_TITLE: "Alert Notifications",
      ALERT_NOTIF_TXT:
        "Activer toutes les notifications d’alerte importantes que vos Beepings peuvent vous envoyer. (detection de mouvement, batterie, enclos…)",
      GENERAL_NOTIF_TITLE: "General Notifications",
      GENERAL_NOTIF_TXT:
        "Les notifications générales vous informent sur l’état de votre balise, ses niveaux de signaux…",
      UPDATE_TITLE: "Updates",
      UPDATE_TXT:
        "Vous permet d’être alerté lorsque que des mises à jour sont disponibles.",
      COMMUNITY_TITLE: "Community",
      COMMUNITY_TXT:
        "Participer à la communauté Beepings en cas d’alerte de vol, ou tout autre etat critique déclaré par un autre membre de la communauté.",
      TECHNICAL_INFO_TITLE: "Technical info",
      TECHNICAL_INFO_TXT:
        "Restez informé des interventions ou évolution technique majeur sur les solutions Beepings.",
      NEWS_TITLE: "Beepings news",
      NEWS_TXT:
        "Recevoir des nouvelles clés sur l’actualité de Beepings, les evenements, les sorties produits.",
      COMMERCIAL_TITLE: "Discount offers",
      COMMERCIAL_TXT:
        "Recevez en exclusivité les offres promotionnelles Beepings."
    },
    PAYMENT: {
      TITLE: "No information available"
    }
  }
};
