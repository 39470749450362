import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { APIService } from 'app/services/api.service';
import { CognitoUtil } from 'app/services/cognito.service';
import { Settings } from 'app/classes/settings';
import { MatSnackBar } from '@angular/material';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-parameters',
  templateUrl: './parameters.component.html',
  styleUrls: ['./parameters.component.scss'],
  animations: fuseAnimations
})
export class ParametersComponent implements OnInit {
  cognitoUser: any;
  settings: any;

  constructor(
    private apiService: APIService,
    private cognitoUtil: CognitoUtil,
    private snackBar: MatSnackBar,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.spinner.show();
    this.getSettings();
  }

  getSettings() {
    this.cognitoUser = this.cognitoUtil.getCurrentUser();
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
    // return this.apiService
    //   .getSettings(this.cognitoUser.username)
    //   .then(notifs => {
    //     this.settings = notifs;

    //     console.log('GET settingsssss', this.settings);
    //   })
    //   .catch(err => console.log(err));
    return this.apiService
      .getSettings(this.cognitoUser.username)
      .subscribe(notifs => {
        this.settings = notifs;

        console.log('GET settingsssss', this.settings);
      });
  }

  postSettings() {
    this.cognitoUser = this.cognitoUtil.getCurrentUser();

    const settingUpdate = { ...this.settings };

    console.log('settingsssss', settingUpdate);
    return this.apiService
      .postSettings(this.cognitoUser.username, settingUpdate)
      .subscribe(updatedSettings =>
        console.log(
          'these settings has been updated on the component',
          updatedSettings,
          this.openSnackBar('Settings updated', 'OK', 'greensnackbar')
        )
      );
    // return this.apiService
    //   .postSettings(this.cognitoUser.username, this.settings)
    //   .then(updatedSettings => console.log('Settings Updated!!', updatedSettings));
  }

  openSnackBar(message: string, action: string, className: string) {
    this.snackBar.open(message, action, {
      duration: 200000,
      panelClass: [className]
    });
  }

  setValue(el, e) {
    if (e.checked) {
      el = 'true';
    } else {
      el = 'false';
    }
    console.log(el);
  }
}
