import { Component, OnInit } from '@angular/core';
import { MapMachine } from '../../+xstate/service';
import { from } from 'rxjs';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
})
export class CalendarComponent implements OnInit {
  dateRange = {
    begin: new Date(),
    end: new Date(),
  };

  constructor(private xstate: MapMachine) {}

  ngOnInit() {
    this.xstate.context$.subscribe(c => {
      if (c.dateRange) {
        this.dateRange = c.dateRange;
      }
    });
  }

  changeDateRange(dateRange) {
    this.xstate.send('CHANGE_DATE_RANGE', { dateRange });
    this.xstate.send('CLOSE');
  }
}
