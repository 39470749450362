import { Component, ViewEncapsulation, OnInit } from '@angular/core';

import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations';
import { APIService } from 'app/services/api.service';
import { CognitoUtil } from 'app/services/cognito.service';

@Component({
  selector: 'mail-confirm',
  templateUrl: './mail-confirm.component.html',
  styleUrls: ['./mail-confirm.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
})
export class MailConfirmComponent {
  cognitoUser: any;
  status: any;

  constructor(private fuseConfigService: FuseConfigService, private apiService: APIService, private cognitoUtil: CognitoUtil) {
    // Configure the layout
    this.fuseConfigService.config = {
      layout: {
        navbar: {
          hidden: true,
        },
        toolbar: {
          hidden: true,
        },
        footer: {
          hidden: true,
        },
        sidepanel: {
          hidden: true,
        },
      },
    };
  }

  sendMailConfirmation() {
    this.cognitoUser = this.cognitoUtil.getCurrentUser();
    this.apiService.sendConfirmationEmail(this.cognitoUser.username).subscribe(status => {
      this.status = status;
    });
    // .catch(err => console.log(err));
  }
}
