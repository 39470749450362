import { Component, OnInit, Input } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';
import { MapMachine } from '../+xstate/service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-time-filter',
  templateUrl: './time-filter.component.html',
  styleUrls: ['./time-filter.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('300ms ease-out', style({ transform: 'translateX(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('300ms ease-in', style({ transform: 'translateX(100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class TimeFilterComponent implements OnInit {
  constructor(private xstate: MapMachine) {}

  ngOnInit() {}

  calendarToggle() {
    this.xstate.send('CALENDAR');
  }

  toggleChangeDateRangeToToday() {
    const now = new Date();
    const beginToday = new Date(`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()}`);
    const endToday = new Date(`${now.getMonth() + 1}/${now.getDate()}/${now.getFullYear()} 23:59:59`);
    const dateRange = {
      begin: beginToday,
      end: endToday,
    };

    this.xstate.send('TODAY', { dateRange });
  }
  toggleChangeDateRangeToYesterday() {
    const now = new Date();
    const beginYesterday = new Date(`${now.getMonth() + 1}/${now.getDate() - 1}/${now.getFullYear()}`);
    const endYesterday = new Date(`${now.getMonth() + 1}/${now.getDate() - 1}/${now.getFullYear()} 23:59:59`);
    const dateRange = {
      begin: beginYesterday,
      end: endYesterday,
    };

    this.xstate.send('YESTERDAY', { dateRange });
  }

  reset() {
    this.xstate.send('RESET');
  }
}
