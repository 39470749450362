import { Component, OnInit, Input } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { MouseEvent } from '@agm/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from 'assets/i18n/en';
import { locale as french } from 'assets/i18n/fr';
import { locale as spanish } from 'assets/i18n/es';

import { APIService } from 'app/services/api.service';
import { CognitoUtil } from 'app/services/cognito.service';

import { User } from 'app/classes/user';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'app/services/device.service';
import { from } from 'rxjs';

import { MapMachine } from './+xstate/service';
import { delay } from 'rxjs/operators';

declare const google: any;

@Component({
  selector: 'main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent implements OnInit {
  mapType = 'roadmap';

  cognitoUser: any;
  user: User;
  isMainPage: boolean;
  zoom = 12;
  lat: number;
  lng: number;

  devices$: any;
  deviceHistory$: any;
  currentDevice: any;
  message: any;

  dateRange = {
    begin: '',
    end: '',
  };

  test: string;
  drawing: any;
  drawingManager: any;
  map: any;
  poly: any;

  constructor(
    private xstate: MapMachine,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private apiService: APIService,
    private cognitoUtil: CognitoUtil,
    private translateService: TranslateService,
    private deviceService: DeviceService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, french, spanish);
  }

  ngOnInit(): void {
    // receives the current device to main component
    this.deviceService.currentMessage.subscribe(message => {
      // this.message = message
      this.lat = message.last_position.lat;
      this.lng = message.last_position.lng;
    });

    this.getUser();
    this.devices$ = this.apiService.getDevicesFromUser(this.cognitoUser.username).pipe(delay(300));
    this.deviceHistory$ = this.xstate.deviceHistory$.pipe(delay(300));

    this.xstate.deviceHistory$.subscribe(value => {
      if (value) {
        this.zoomToObject(value);
      }
    });
  }

  zoomToObject(item) {
    // @ts-ignore
    if (window.google) {
      const bounds = new google.maps.LatLngBounds();
      const points = item.items.map(point => point.position);
      // @ts-ignore

      console.log('POIONTS', points);

      for (let n = 0; n < points.length; n++) {
        if (points[n].lat && points[n].lng) {
          bounds.extend(points[n]);
        }
      }
      // @ts-ignore
      window.map.fitBounds(bounds);
    }
  }

  mapClick() {
    this.xstate.send('CLICK_MAP');
  }

  changeCurrentDevice(device) {
    this.xstate.send('CLICK_DEVICE', { selected: device });

    this.currentDevice = device;
  }

  getDate(date) {
    return new Date(date * 1000);
  }

  getUser() {
    this.cognitoUser = this.cognitoUtil.getCurrentUser();

    return this.apiService.getUser(this.cognitoUser.username).subscribe(
      user => {
        this.user = user;
        localStorage.setItem('userPreferredLang', user.language);
        this.translateService.use(localStorage.getItem('userPreferredLang'));
        console.log(this.user);
      },
      err => {
        console.log(err);
        // location.reload();
      },
    );
  }

  enclosComplet(event) {
    console.log('ENCLOS COMPLETE', event);
    console.log('this.drawingManager', this.drawingManager);
    // console.log('agmDrawingManager', this.agmDrawingManager);
  }

  setLanguage() {
    localStorage.setItem('userPreferredLang', this.user.language);
    this.translateService.use(localStorage.getItem('userPreferredLang'));
  }

  clickedMarker(label: string, index: number) {
    console.log(`clicked the marker: ${label || index}`);
  }

  onMapReady(map) {
    // @ts-ignore
    window.map = this.map = map;
    this.drawingManager = new google.maps.drawing.DrawingManager({
      drawingMode: google.maps.drawing.OverlayType.POLYLINE,
      drawingControl: false,
      drawingControlOptions: {
        position: google.maps.ControlPosition.TOP_CENTER,
        drawingModes: ['polyline'],
      },
      polygonOptions: {
        fillColor: '#50c9b5',
        strokeColor: '#50c9b5',
        fillOpacity: 0.5,
        strokeWeight: 5,
        clickable: false,
        editable: true,
        zIndex: 1,
      },
    });

    const polygonOptions = {
      fillColor: '#50c9b5',
      strokeColor: '#50c9b5',
      fillOpacity: 0.5,
      strokeWeight: 5,
      clickable: false,
      editable: true,
      zIndex: 1,
    };

    this.xstate.match(this.xstate.selectors.enclos({ addEnclo: 'drawing' })).subscribe(isDrawing => {
      console.log('isDrawing', isDrawing);
      if (isDrawing) {
        this.startEnclo();
      } else {
        this.stopEnclo();
      }
    });
  }

  startEnclo() {
    this.disable();

    this.poly && this.poly.setMap(null);
    google.maps.event.addDomListener(this.map.getDiv(), 'mousedown', e => {
      this.drawFreeHand();
    });
  }
  stopEnclo() {
    this.poly && this.poly.setMap(null);
  }

  disable() {
    this.map.setOptions({
      draggable: false,
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: false,
    });
  }

  enable() {
    this.map.setOptions({
      draggable: true,
      zoomControl: true,
      scrollwheel: true,
      disableDoubleClickZoom: true,
    });
  }

  drawFreeHand() {
    // the polygon
    const poly = new google.maps.Polyline({ map: this.map, clickable: false, fillColor: '#50c9b5', strokeColor: '#50c9b5' });

    // move-listener
    const move = google.maps.event.addListener(this.map, 'mousemove', e => {
      poly.getPath().push(e.latLng);
    });

    // mouseup-listener
    google.maps.event.addListenerOnce(this.map, 'mouseup', e => {
      google.maps.event.removeListener(move);
      const path = poly.getPath();
      poly.setMap(null);
      this.poly = new google.maps.Polygon({ map: this.map, path, fillColor: '#50c9b5', strokeColor: '#50c9b5' });

      google.maps.event.clearListeners(this.map.getDiv(), 'mousedown');

      this.xstate.send('COMPLETE', {
        path: path.g.map(p => {
          return { lat: p.lat(), lng: p.lng() };
        }),
      });

      this.enable();
    });
  }

  getCenterLat(points) {
    const latitudes = points.map(p => p.lat).sort((a, b) => a < b);

    const latitudeMax = Math.max(...latitudes);
    const latitudeMin = Math.min(...latitudes);

    return latitudeMin + (latitudeMax - latitudeMin) / 2;
  }

  getCenterLng(points) {
    const longitudes = points.map(p => p.lng);

    const longitudeMax = Math.max(...longitudes);
    const longitudeMin = Math.min(...longitudes);

    return longitudeMin + (longitudeMax - longitudeMin) / 2;
  }
}

// just an interface for type safety.
interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
