import { Component, OnInit } from '@angular/core';
import { CognitoUtil } from 'app/services/cognito.service';
import { APIService } from 'app/services/api.service';
import { User } from 'app/classes/user';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from 'assets/i18n/en';
import { locale as french } from 'assets/i18n/fr';
import { locale as spanish } from 'assets/i18n/es';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-navbar-secondary',
  templateUrl: './navbar-secondary.component.html',
  styleUrls: ['./navbar-secondary.component.scss'],
})
export class NavbarSecondaryComponent implements OnInit {
  cognitoUser: any;
  user: User;
  selectedPicFile: any = null;
  image: any;

  constructor(
    private cognitoUtil: CognitoUtil,
    private apiService: APIService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private snackBar: MatSnackBar,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, french, spanish);
  }

  ngOnInit() {
    // Get user anme
    this.getUser();
  }

  getUser() {
    this.cognitoUser = this.cognitoUtil.getCurrentUser();

    return this.apiService.getUser(this.cognitoUser.username).subscribe(
      user => {
        this.user = user;
      },
      err => {
        console.log(err);
      },
    );

    // return this.apiService
    //   .getUser(this.cognitoUser.username)
    //   .then(user => {
    //       this.user = user;
    //   })
    //   .catch(err => console.log(err));
  }

  onFileSelected(event) {
    this.selectedPicFile = event.target.files[0];
    this.getBase64(this.selectedPicFile)
      .then(data => (this.image = data))
      .catch(err => console.error(err));
    setTimeout(() => {
      this.onPictureUpload();
    }, 1000);
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // const representation = (reader.result as string).split(',').pop();
      reader.onload = () => resolve((reader.result as any).split(',').pop());
      reader.onerror = error => reject(error);
    });
  }

  onPictureUpload() {
    // this.image = this.image.replace('', '');
    this.apiService
      .sendProfilePic(this.cognitoUser.username, this.image)
      .subscribe(userPic => console.log('img sent!!!!', userPic, this.openSnackBar('Image updated', 'OK')));
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }
}
