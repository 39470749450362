// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,

  endpoint: 'https://api.beepings.com/v1',

  region: 'eu-west-1',

  identityPoolId: 'eu-west-1:3674f834-934d-4c6b-8193-cd7f41649f9b',
  userPoolId: 'eu-west-1_ua1P6e5V2',
  clientId: '2shlg1nm2ntnst09t89l610mv1',

  rekognitionBucket: 'rekognition-pics',
  albumName: 'usercontent',
  bucketRegion: 'eu-west-1',

  ddbTableName: 'LoginTrail',

  cognito_idp_endpoint: '',
  cognito_identity_endpoint: '',
  sts_endpoint: '',
  dynamodb_endpoint: '',
  s3_endpoint: '',

  // URL of production API Key
  apiKey: '70NhZ8F4Zj7L9VqNYCdMM3jjpMJOiPyG8IweYwfX',

  GoogleMapAPIKey: 'AIzaSyBxlzghL1mpXptP8gTl-Yv_vDF0xFKmyuk',

  mapbox: {
    accessToken:
      'pk.eyJ1IjoiZXRvcmllbGxvIiwiYSI6ImNqcm5vbWIzejB0ZHo0M21zZWhzejF5YnAifQ.qAMa39FkIsdrgyRLsxkZZw'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
