import { Component, OnInit } from '@angular/core';
import { MapMachine } from '../+xstate/service';
import { from } from 'rxjs';
import { trigger, transition, style, animate } from '@angular/animations';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-device-controls',
  templateUrl: './device-controls.component.html',
  styleUrls: ['./device-controls.component.scss'],
  animations: [
    trigger('enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateY(100%)', opacity: 0 }),
        animate('300ms ease-out', style({ transform: 'translateY(0)', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ transform: 'translateY(0)', opacity: 1 }),
        animate('300ms ease-in', style({ transform: 'translateY(100%)', opacity: 0 })),
      ]),
    ]),
  ],
})
export class DeviceControlsComponent implements OnInit {
  constructor(private xstate: MapMachine) {}

  ngOnInit() {}
}
