export interface Enclosure {}

export interface Errors {
  [key: string]: string;
}

export class GetEnclosureFail {
  readonly type = 'FAILURE';
  constructor(public errors: Errors) {
    if (this.errors) {
      alert(`Erreur lors de la récupération d'enclo virutuel : ${this.errors.statusText}`);
    } else {
      alert(`Erreur lors de la récupération d'enclo virutuel : verifier votre connection internet`);
    }
  }
}
export class GetEnclosureSuccess {
  readonly type = 'SUCCESS';
  constructor(public enclosure: Enclosure) {}
}

export class GetDeviceNotificationsFail {
  readonly type = 'FAILURE';
  constructor(public errors: Errors) {
    if (this.errors) {
      alert(`Erreur lors de la récupération  de notifications de l'enclo virutuel : ${this.errors.statusText}`);
    } else {
      alert(`Erreur lors de la récupération  de notifications de l'enclo virutuel : verifier votre connection internet`);
    }
  }
}
export class GetDeviceNotificationsSuccess {
  readonly type = 'SUCCESS';
  constructor(public notifications: any) {}
}

export class UpdateDeviceSettingsFail {
  readonly type = 'FAILURE';
  constructor(public errors: Errors) {
    if (this.errors) {
      alert(`Erreur lors d'update des reglages de l'enclo virutuel : ${this.errors.statusText}`);
    } else {
      alert(`Erreur lors d'update des reglages de l'enclo virutuel : verifier votre connection internet`);
    }
  }
}
export class UpdateDeviceSettingsSuccess {
  readonly type = 'SUCCESS';
  constructor(public setting: any) {}
}

export class GetDeviceSettingsFail {
  readonly type = 'FAILURE';
  constructor(public errors: Errors) {
    if (this.errors) {
      alert(`Erreur lors de la récupération des reglages : ${this.errors.statusText}`);
    } else {
      alert(`Erreur lors de la récupération des reglages : verifier votre connection internet`);
    }
  }
}

export class GetDeviceHistorySuccess {
  readonly type = 'SUCCESS';
  constructor(public history: any) {}
}
export class GetDeviceHistoryFail {
  readonly type = 'FAILURE';
  constructor(public errors: Errors) {
    if (this.errors) {
      alert(`Erreur lors de la récupération de l'historique : ${this.errors.statusText}`);
    } else {
      alert(`Erreur lors de la récupération de l'historique : verifier votre connection internet`);
    }
  }
}
export class GetDeviceSettingsSuccess {
  readonly type = 'SUCCESS';
  constructor(public setting: any) {}
}

export class AddEnclosureSharingFail {
  readonly type = 'FAILURE';
  constructor(public errors: Errors) {
    if (this.errors && !this.errors.message) {
      alert(`Erreur lors de l'ajout d'un utilisateur sur d'enclo virutuel : ${this.errors.statusText}`);
    }
    if (this.errors.message === 'user not found') {
      alert(`L'email entrée ne correspond pas à un utilisateur beeping`);
    } else {
      alert(`Erreur lors de l'ajout d'un utilisateur sur d'enclo virutuel : verifier votre connection internet`);
    }
  }
}
export class AddEnclosureSharingSuccess {
  readonly type = 'SUCCESS';
  constructor(public enclosure: Enclosure) {}
}

export class GetEnclosureSharingFail {
  readonly type = 'FAILURE';
  constructor(public errors: Errors) {
    if (this.errors) {
      alert(`Erreur lors de la récupération de partage  de l'enclo virutuel : ${this.errors.statusText}`);
    } else {
      alert(`Erreur lors de la récupération de partage  de l'enclo virutuel : verifier votre connection internet`);
    }
  }
}
export class GetEnclosureSharingSuccess {
  readonly type = 'SUCCESS';
  constructor(public enclosure: Enclosure) {}
}
export class DeleteEnclosureSharingFail {
  readonly type = 'FAILURE';
  constructor(public errors: Errors) {
    if (this.errors) {
      alert(`Erreur lors de la supressiono de partage  de l'enclo virutuel : ${this.errors.statusText}`);
    } else {
      alert(`Erreur lors de la supressiono de partage  de l'enclo virutuel : verifier votre connection internet`);
    }
  }
}
export class DeleteEnclosureSharingSuccess {
  readonly type = 'SUCCESS';
  constructor(public enclosure: Enclosure) {}
}

export class AddEnclosureFail {
  readonly type = 'FAILURE';
  constructor(public errors: Errors) {
    if (this.errors) {
      alert(`Erreur lors de la creation d'enclo virutuel : ${this.errors.statusText}`);
    } else {
      alert(`Erreur lors de la creation d'enclo virutuel : verifier votre connection internet`);
    }
  }
}
export class AddEnclosureSuccess {
  readonly type = 'SUCCESS';
  constructor(public enclosure: Enclosure) {}
}

export class DeleteEnclosureFail {
  readonly type = 'FAILURE';
  constructor(public errors: Errors) {
    if (this.errors) {
      alert(`Erreur lors de la suppression d'enclo virutuel : ${this.errors.statusText}`);
    } else {
      alert(`Erreur lors de la suppression d'enclo virutuel : verifier votre connection internet`);
    }
  }
}
export class DeleteEnclosureSuccess {
  readonly type = 'SUCCESS';
  constructor(public enclosure: Enclosure) {}
}
