export const locale = {
  lang: "fr",
  data: {
    LOGIN: {
      LOGIN: "Connexion",
      PASSWORD: "Mot de passe",
      FORGOTTEN: "Mot de passe oublié",
      OR: "Ou",
      NO_ACCOUNT: "Pas encore de compte?",
      REINITIALIZE: "Réinitialiser votre mot de passe",
      SEND_EMAIL: "Envoyer le lien",
      BACK: "Retour au login",
      VISIT: "Cliquez ici",
      CLOSE: "Fermer"
    },
    MENU: {
      FULL_SCREEN: "Plein ecran",
      INFO: "Informations",
      NOTIF: "Notifications",
      PARAM: "Paramètres",
      PAYMENT: "Paiement",
      LOGOUT: "Deconnexion"
    },
    MAIN: {
      HELLO: "Bonjour Dünya!"
    },
    PROFILE: {
      TITLE_USER: "Informations utilisateur",
      FIRSTNAME: "Prénom",
      LASTNAME: "Nom",
      BIRTHDATE: "Date de naissance",
      COUNTRY: "Pays",
      PHONE: "Telephone",
      LANGUAGE: "Langue",
      TITLE_DELIVER: "Informations Livraison",
      TITLE_BILL: "Informations Facturation",
      ACTION: "Sauvegarder",
      CHOSE_PIC: "Rechercher photo",
      UPLOAD: "Televerser",
      PIC_LABEL: "Image du profil"
    },
    NOTIFICATIONS: {
      TODAY: "Aujourd'hui",
      YESTERDAY: "Hier",
      BEFORE: "Avant"
    },
    SETTINGS: {
      ALERT_NOTIF_TITLE: "Alertes de notifications",
      ALERT_NOTIF_TXT:
        "Activer toutes les notifications d’alerte importantes que vos Beepings peuvent vous envoyer. (detection de mouvement, batterie, enclos…)",
      GENERAL_NOTIF_TITLE: "Notifications generales",
      GENERAL_NOTIF_TXT:
        "Les notifications générales vous informent sur l’état de votre balise, ses niveaux de signaux…",
      UPDATE_TITLE: "Mise à jour",
      UPDATE_TXT:
        "Vous permet d’être alerté lorsque que des mises à jour sont disponibles.",
      COMMUNITY_TITLE: "Communauté",
      COMMUNITY_TXT:
        "Participer à la communauté Beepings en cas d’alerte de vol, ou tout autre etat critique déclaré par un autre membre de la communauté.",
      TECHNICAL_INFO_TITLE: "Informations techniques",
      TECHNICAL_INFO_TXT:
        "Restez informé des interventions ou évolution technique majeur sur les solutions Beepings.",
      NEWS_TITLE: "Actualités Beepings",
      NEWS_TXT:
        "Recevoir des nouvelles clés sur l’actualité de Beepings, les evenements, les sorties produits.",
      COMMERCIAL_TITLE: "Offres commerciales",
      COMMERCIAL_TXT:
        "Recevez en exclusivité les offres promotionnelles Beepings."
    },
    PAYMENT: {
      TITLE: "Aucune information disponible"
    }
  }
};
