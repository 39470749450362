import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  isMainPage = true;
  /**
   * Constructor
   */
  constructor(private router: Router, location: Location) {
    router.events.subscribe(val => {
      if (location.path() === '/main' || location.path() === '/' || location.path() === '/login') {
        this.isMainPage = true;
      } else {
        this.isMainPage = false;
      }
    });
  }
}
