import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { locale as english } from 'assets/i18n/en';
import { locale as french } from 'assets/i18n/fr';
import { locale as spanish } from 'assets/i18n/es';

import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { UserLoginService } from '../../../services/user-login.service';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  isMainPage = true;

  isFullScreen = false;

  // Private
  private unsubscribeAll: Subject<any>;

  constructor(
    private userService: UserLoginService,
    private fuseConfigService: FuseConfigService,
    private fuseSidebarService: FuseSidebarService,
    private translateService: TranslateService,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private router: Router,
    location: Location,
  ) {
    router.events.subscribe(val => {
      if (location.path() === '/main' || location.path() === '/' || location.path() === '/login') {
        this.isMainPage = true;
      } else {
        this.isMainPage = false;
      }
    });
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50',
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107',
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336',
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD',
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161',
      },
    ];

    this.fuseTranslationLoaderService.loadTranslations(english, french, spanish);
    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us',
      },
      {
        id: 'fr',
        title: 'French',
        flag: 'fr',
      },
      {
        id: 'es',
        title: 'Spanish',
        flag: 'es',
      },
    ];

    this.navigation = navigation;

    // Set the private defaults
    this.unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  ngOnInit(): void {
    // Subscribe to the config changes
    this.fuseConfigService.config.pipe(takeUntil(this.unsubscribeAll)).subscribe(settings => {
      this.horizontalNavbar = settings.layout.navbar.position === 'top';
      this.rightNavbar = settings.layout.navbar.position === 'right';
      this.hiddenNavbar = settings.layout.navbar.hidden === true;
    });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this.translateService.currentLang,
    });
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  toggleSidebarOpen(key): void {
    this.fuseSidebarService.getSidebar(key).toggleOpen();
  }

  search(value): void {
    // Do your search here...
    console.log(value);
  }

  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this.translateService.use(lang.id);
  }

  logout(): void {
    this.userService.logout();
    this.router.navigate(['/login']);
  }

  fullScreen(): void {
    if (this.isFullScreen) {
      document.exitFullscreen();
    } else {
      const elem = document.documentElement;
      // @ts-ignore
      const methodToBeInvoked = elem.requestFullscreen || elem.mozRequestFullscreen || elem.msRequestFullscreen;
      if (methodToBeInvoked) {
        methodToBeInvoked.call(elem);
      }
    }

    this.isFullScreen = !this.isFullScreen;
  }
}
