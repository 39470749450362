import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatIconModule,
  MatDividerModule,
  MatListModule,
  MatSlideToggleModule,
  MatCardModule
} from '@angular/material';

import { FuseNavigationModule } from '@fuse/components';
import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarVerticalStyle2Component } from 'app/layout/components/navbar/vertical/style-2/style-2.component';

@NgModule({
  declarations: [NavbarVerticalStyle2Component],
  imports: [
    MatButtonModule,
    MatIconModule,
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    MatCardModule,
    FuseSharedModule,
    FuseNavigationModule
  ],
  exports: [NavbarVerticalStyle2Component]
})
export class NavbarVerticalStyle2Module {}
