import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {
  MatButtonModule,
  MatIconModule,
  MatDialogModule,
  MatCheckboxModule,
  MatFormFieldModule,
  MatInputModule,
  MatDialog,
  MatSelectModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatCardModule,
  MatListModule,
} from '@angular/material';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import { UserLoginService } from './services/user-login.service';
import { CognitoUtil } from './services/cognito.service';
import { AwsUtil } from './services/aws.service';
import { getErrorHandler } from './services/error-handler.service';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';

import { MapService } from './services/map.service';
import { AppRoutingModule } from './app-routing.module';
import { LoginComponent, DialogElementsExampleDialogComponent } from './main/public/authentication/login/login.component';
import { ForgotPasswordComponent } from './main/public/authentication/forgot-password/forgot-password.component';

import { Error404Component } from './main/public/errors/404/error-404.component';
import { DatepickerApiExampleComponent } from './layout/components/datepicker/datepicker.component';
import { APIService } from './services/api.service';
import { ProfileComponent, DialogPasswordDialog } from './main/secure/profile/profile.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MailConfirmComponent } from './main/public/authentication/mail-confirm/mail-confirm.component';
import { ParametersComponent } from './main/secure/parameters/parameters.component';
import { NotificationsComponent } from './main/secure/notifications/notifications.component';
import { NavbarSecondaryComponent } from './layout/components/navbar-secondary/navbar-secondary.component';
import { PaymentComponent } from './main/secure/payment/payment.component';
import { ImgUploadComponent } from './layout/components/img-upload/img-upload.component';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { ResetPasswordComponent } from './main/public/authentication/reset-password/reset-password.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DeviceService } from './services/device.service';

import { MatNativeDateModule } from '@angular/material';
import { MainModule } from './main/secure/main/main.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ResetPasswordComponent,
    MailConfirmComponent,
    ForgotPasswordComponent,
    ProfileComponent,
    ParametersComponent,
    NotificationsComponent,
    Error404Component,
    DialogElementsExampleDialogComponent,
    DialogPasswordDialog,
    DatepickerApiExampleComponent,
    ProfileComponent,
    ParametersComponent,
    NotificationsComponent,
    NavbarSecondaryComponent,
    PaymentComponent,
    ImgUploadComponent,
  ],
  imports: [
    MainModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxSpinnerModule,
    // RouterModule.forRoot(appRoutes),
    RouterModule,
    AppRoutingModule,
    ReactiveFormsModule,
    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,
    MatDatepickerModule,
    // Material
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    MatCardModule,
    MatListModule,
    MatInputModule,
    MatNativeDateModule,

    InternationalPhoneNumberModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,

    // App modules
    LayoutModule,
  ],
  providers: [
    { provide: ErrorHandler, useFactory: getErrorHandler },
    UserLoginService,
    CognitoUtil,
    AwsUtil,
    MapService,
    MatDialog,
    APIService,
    DeviceService,
  ],

  entryComponents: [DialogElementsExampleDialogComponent, DialogPasswordDialog],
  bootstrap: [AppComponent],
})
export class AppModule {}
