import { Component, OnInit, Inject } from '@angular/core';
import { CognitoUtil, CognitoCallback } from 'app/services/cognito.service';
import { APIService } from 'app/services/api.service';
import { UserProfile, User } from 'app/classes/user';
import { FormGroup, FormControl } from '@angular/forms';
import { fuseAnimations } from '@fuse/animations';
import { MatSnackBar, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
  CognitoAccessToken,
  CognitoUserSession,
  ICognitoUserAttributeData,
} from 'amazon-cognito-identity-js';

import * as _ from 'lodash';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from 'assets/i18n/en';
import { locale as french } from 'assets/i18n/fr';
import { locale as spanish } from 'assets/i18n/es';

import { TranslateService } from '@ngx-translate/core';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import * as moment from 'moment';
import { AwsUtil } from 'app/services/aws.service';
import { NgxSpinnerService } from 'ngx-spinner';

export class NewPasswordUser {
  username: string;
  existingPassword: string;
  password: string;
}

export interface DialogProfileData {
  newPassword: string;
  email: string;
}

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  animations: fuseAnimations,
})
export class ProfileComponent implements OnInit {
  // user: UserProfile = {
  //   'id': '',
  //   'birth_date': '',
  //   'phone_area_code': '',
  //   'first_name': '',
  //   'last_name': '',
  //   'email': '',
  //   'phone': '',
  //   'language': '',
  //   'country': '',
  //   'terms': false
  // };

  user: User;
  cognitoUser: any;
  profile: any;
  profileForm = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    area_code: new FormControl(''),
    phone: new FormControl(''),
    email: new FormControl(''),
    oldPassword: new FormControl(''),
    newPassword: new FormControl(''),
    language: new FormControl(''),
    country: new FormControl(''),
    birthdate: new FormControl(''),
    picture: new FormControl(''),
  });
  languages: any;
  selectedLanguage: any;

  loading = true;

  selectedPicFile: any = null;

  image: any;

  convertedPhoneAreaCode: any;
  oldPassword: string;
  newPassword: string;

  constructor(
    private apiService: APIService,
    private cognitoUtil: CognitoUtil,
    private fuseTranslationLoaderService: FuseTranslationLoaderService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    public awsUtil: AwsUtil,
    private fuseSplashScreenService: FuseSplashScreenService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
    this.fuseTranslationLoaderService.loadTranslations(english, french, spanish);
    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us',
      },
      {
        id: 'fr',
        title: 'French',
        flag: 'fr',
      },
      {
        id: 'es',
        title: 'Spanish',
        flag: 'es',
      },
    ];
  }

  ngOnInit() {
    // loading screen
    this.spinner.show();
    this.getUser();

    // this.user.picture = this.image;
    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {
      id: this.translateService.currentLang,
    });
    // this.translateService.use(this.user.language);
  }

  getUser() {
    this.cognitoUser = this.cognitoUtil.getCurrentUser();
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
    return this.apiService.getUser(this.cognitoUser.username).subscribe(
      user => {
        this.user = user;
        this.convertedPhoneAreaCode = this.formatPhoneAreaCode(this.user.phone_area_code);
        // this.user.phone_area_code = this.formatPhoneAreaCode(this.user.phone_area_code)
        this.loading = false;
        console.log(this.user);
      },
      err => {
        console.log(err);
      },
    );

    // return this.apiService
    //   .getUser(this.cognitoUser.username)
    //   .then(user => {
    //     this.user = user;
    //     this.convertedPhoneAreaCode = this.formatPhoneAreaCode(this.user.phone_area_code);
    //     //this.user.phone_area_code = this.formatPhoneAreaCode(this.user.phone_area_code)
    //     this.loading = false;
    //     console.log(this.user);
    //   })
    //   .catch(err => console.log(err));
  }

  updateUserData() {
    console.log('Are we sending the birthdate', this.user);
    // const userUpdates = {
    //   'id': this.user.id,
    //   'birth_date': this.user.birth_date,
    //   'phone_area_code': this.user.phone_area_code,
    //   'first_name': this.user.first_name,
    //   'last_name': this.user.last_name,
    //   'email': this.user.email,
    //   'phone': this.user.phone,
    //   'language': this.user.language,
    //   'country': this.user.country,
    //   'terms': this.user.terms
    // }
    // this.cognitoChangeEmail();

    this.user.picture = this.image;
    this.user.phone_area_code = parseInt(this.convertedPhoneAreaCode.split('+').join(''));
    // this.user.phone_area_code = parseInt(String(this.user.phone_area_code).split("+").join(""));
    // if (this.user.birth_date !== this.user.birth_date.format("YYYY-MM-DD")) {
    //   this.user.birth_date = this.user.birth_date.format("YYYY-MM-DD");
    // }
    this.user.birth_date = this.dateFormatter(this.user.birth_date);

    console.log('langggggg', this.user.language);
    localStorage.setItem('userPreferredLang', this.user.language);
    this.setLanguage(this.user.language);
    // this.setLanguage(this.user.language);
    return this.apiService
      .updateUserProfile(this.cognitoUser.username, this.user)
      .subscribe(updatedUser =>
        console.log('this user has been updated on the component', updatedUser, this.openSnackBar('Profile updated', 'OK')),
      );
    // return this.apiService
    //   .updateUserProfile(this.cognitoUser.username, this.user)
    //   .then(
    //     updatedUser => console.log('User Updated!!! Yay!!', updatedUser,
    //       this.openSnackBar('Profile updated', 'OK')));
  }

  dateFormatter(date: string) {
    return moment(date).format('YYYY-MM-DD');
  }

  formatPhoneAreaCode(phone_area_code) {
    if (typeof phone_area_code === 'string') {
      return parseInt(
        String(phone_area_code)
          .split('+')
          .join(''),
      );
    }
    return `+${phone_area_code}`;
  }

  openSnackBar(message: string, action: string) {
    this.snackBar.open(message, action, {
      duration: 2000,
    });
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.profileForm.value);
  }

  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this.translateService.use(lang.id);

    localStorage.setItem('userPreferredLang', this.user.language);
  }

  onFileSelected(event) {
    this.selectedPicFile = event.target.files[0];
    this.getBase64(this.selectedPicFile)
      .then(data => (this.image = data))
      .catch(err => console.error(err));
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      // const representation = (reader.result as string).split(',').pop();
      reader.onload = () => resolve((reader.result as any).split(',').pop());
      reader.onerror = error => reject(error);
    });
  }

  onPictureUpload() {
    // this.image = this.image.replace('', '');
    this.apiService.sendProfilePic(this.cognitoUser.username, this.image).subscribe(userPic => console.log('img sent!!!!', userPic));
  }

  updateCognitoEmail() {
    const email = this.user.email;

    const attributeList = [];
    const attribute = {
      Name: 'email',
      Value: email,
    };

    const attributes = new CognitoUserAttribute(attribute);
    attributeList.push(attributes);

    const cognitoUser = this.cognitoUtil.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((error, sessionResult) => {
        if (sessionResult) {
          const idToken = sessionResult.getIdToken().getJwtToken();

          cognitoUser.updateAttributes(attributeList, (err, result) => {
            if (err || error) {
              alert(err || error);
              return;
            }
            console.log('call result: ' + result);
          });
        }
      });
    }
  }
  updateAndCognitoEmailChange() {
    this.updateCognitoEmail();
    this.updateUserData();
  }

  openPasswordModal() {
    this.dialog.open(DialogPasswordDialog, {
      data: {
        newPassword: this.newPassword,
        email: this.user.email,
      },
      height: '400px',
      width: '400px',
    });
  }

  newPasswordControl(): void {
    const userData = {
      Username: this.cognitoUser.username,
      Pool: this.cognitoUtil.getUserPool(),
    };
    const opassword = this.oldPassword;
    const npassword = this.newPassword;
    console.log('UserLoginService: Params set...Authenticating the user');
    const cognitoUser = new CognitoUser(userData);
    if (cognitoUser != null) {
      cognitoUser.getSession((err, sessionResult) => {
        if (sessionResult) {
          const accessToken = sessionResult.getAccessToken().getJwtToken();

          cognitoUser.changePassword(opassword, npassword, (err, result) => {
            if (err) {
              // alert(err);
              console.log(err);
              return;
            }
            console.log('call result: ' + result);
          });
        }
      });
    }
  }
}

@Component({
  selector: 'dialog-password',
  templateUrl: 'dialog-password.html',
  styleUrls: ['./dialog-password.scss'],
  animations: fuseAnimations,
})
export class DialogPasswordDialog implements OnInit {
  cognitoUser: any;
  oldPassword: string;
  user: User;
  confirmationForm = new FormGroup({
    oldPassword: new FormControl(''),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogProfileData,
    private cognitoUtil: CognitoUtil,
    private snackBar: MatSnackBar,
    private apiService: APIService,
  ) {}

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.cognitoUser = this.cognitoUtil.getCurrentUser();

    return this.apiService.getUser(this.cognitoUser.username).subscribe(
      user => {
        this.user = user;
        console.log(this.user);
      },
      err => {
        console.log(err);
      },
    );
  }

  updateAndCognitoEmailAndPasswordChange() {
    this.updateCognitoEmail();
    this.updateUserData();
    this.confirmCognito();
  }

  updateCognitoEmail() {
    const email = this.data.email;

    const attributeList = [];
    const attribute = {
      Name: 'email',
      Value: email,
    };

    const attributes = new CognitoUserAttribute(attribute);
    attributeList.push(attributes);

    const cognitoUser = this.cognitoUtil.getCurrentUser();
    if (cognitoUser != null) {
      cognitoUser.getSession((error, sessionResult) => {
        if (sessionResult) {
          const idToken = sessionResult.getIdToken().getJwtToken();

          cognitoUser.updateAttributes(attributeList, (err, result) => {
            if (err || error) {
              alert(err || error);
              return;
            }
            console.log('call result: ' + result);
          });
        }
      });
    }
  }
  updateUserData() {
    this.user.email = this.data.email;
    console.log('useeeeeer', this.user);
    return this.apiService
      .updateUserProfile(this.cognitoUser.username, this.user)
      .subscribe(updatedUser => console.log('this user has been updated on the component', updatedUser));
  }

  confirmCognito() {
    this.cognitoUser = this.cognitoUtil.getCurrentUser();
    const userData = {
      Username: this.cognitoUser.username,
      Pool: this.cognitoUtil.getUserPool(),
    };
    const opassword = this.oldPassword;
    const npassword = this.data.newPassword;
    console.log('UserLoginService: Params set...Authenticating the user');
    const cognitoUser = new CognitoUser(userData);
    if (cognitoUser != null) {
      cognitoUser.getSession((error, sessionResult) => {
        if (sessionResult) {
          const accessToken = sessionResult.getAccessToken().getJwtToken();

          cognitoUser.changePassword(opassword, npassword, (err, result) => {
            if (err || error) {
              console.log(err || error);
              return;
            }
            console.log('call result: ' + result);
          });
        }
      });
    }
  }
}
