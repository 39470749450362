import { Component, OnInit } from '@angular/core';
import { MapMachine } from '../../+xstate/service';

@Component({
  selector: 'app-enclo-delete',
  templateUrl: './enclo-delete.component.html',
  styleUrls: ['./enclo-delete.component.scss'],
})
export class EncloDeleteComponent implements OnInit {
  constructor(private xstate: MapMachine) {}

  ngOnInit() {}
}
