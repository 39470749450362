import { Component, OnInit } from '@angular/core';
import { MapMachine } from '../../+xstate/service';

@Component({
  selector: 'app-enclo-setting',
  templateUrl: './enclo-setting.component.html',
  styleUrls: ['./enclo-setting.component.scss'],
})
export class EncloSettingComponent implements OnInit {
  in = false;
  out = false;
  name: string;

  constructor(private xstate: MapMachine) {}

  ngOnInit() {}
}
