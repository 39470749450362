import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DeviceService {
  defaultLastPosition = {
    lng: 2.3486904,
    lat: 48.8535277,
  };

  private messageSource = new BehaviorSubject({
    last_position: this.defaultLastPosition,
  } as any);
  currentMessage = this.messageSource.asObservable();

  constructor() {}

  changeMessage(message: any) {
    this.messageSource.next(message);
  }
}
