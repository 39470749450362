import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'devices',
    title: 'Zen devices',
    translate: 'NAV.DEVICES',
    type: 'group',
    children: [
      {
        id: 'main',
        title: 'Main',
        translate: 'NAV.MAIN.TITLE',
        type: 'item',
        icon: 'portrait',
        url: '/main',
        badge: {
          title: '25',
          translate: 'NAV.MAIN.BADGE',
          bg: '#F44336',
          fg: '#FFFFFF'
        }
      }
    ]
  }
];
