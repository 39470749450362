import { Component, OnInit } from '@angular/core';
import { MapMachine } from '../../+xstate/service';

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.scss'],
})
export class ShareComponent implements OnInit {
  constructor(private xstate: MapMachine) {}

  ngOnInit() {}

  addUser() {
    const email = window.prompt("entrez l'email de l'utilisateur avec qui partager ce beepings", 'email');
    if (email) {
      this.xstate.send('ADD', { email });
    }
  }
}
