import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from 'assets/i18n/en';
import { locale as french } from 'assets/i18n/fr';
import { locale as spanish } from 'assets/i18n/es';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss'],
  animations: fuseAnimations
})
export class PaymentComponent implements OnInit {
  constructor(
    private _fuseTranslationLoaderService: FuseTranslationLoaderService
  ) {
    this._fuseTranslationLoaderService.loadTranslations(
      english,
      french,
      spanish
    );
  }

  ngOnInit() {}
}
