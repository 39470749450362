import { Component, OnInit } from '@angular/core';
import { MapMachine } from '../+xstate/service';
import { from } from 'rxjs';
@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.scss'],
})
export class ModalsComponent implements OnInit {
  constructor(private xstate: MapMachine) {}

  ngOnInit() {}
}
