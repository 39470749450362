import {
  NgModule,
  Component,
  Input,
  Output,
  ElementRef,
  forwardRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { APIService } from 'app/services/api.service';
import { CognitoUtil } from 'app/services/cognito.service';

@Component({
  selector: 'app-img-upload',
  templateUrl: './img-upload.component.html',
  styleUrls: ['./img-upload.component.scss']
})
export class ImgUploadComponent implements ControlValueAccessor {
  selectedFile: File = null;
  cognitoUser: any;
  @Input() showFileNameInput: boolean;
  @Input() uploadButtonText: string;
  selectedPicFile: any;
  constructor(
    private apiService: APIService,
    private cognitoUtil: CognitoUtil
  ) {}

  writeValue(value: any) {
    // Handle write value
  }
  propagateChange = (_: any) => {};
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  registerOnTouched() {}

  changeListener($event): void {
    // debugger; // uncomment this for debugging purposes

    this.readThis($event.target);
  }
  readThis(inputValue: any): void {
    // debugger; // uncomment this for debugging purposes
    const file: File = inputValue.files[0];
    const myReader: FileReader = new FileReader();

    myReader.onloadend = e => {
      this.propagateChange(myReader.result);
      this.selectedFile = file;
    };
    myReader.readAsDataURL(file);
  }
  onPictureUpload() {
    this.cognitoUser = this.cognitoUtil.getCurrentUser();
    this.apiService
      .sendProfilePic(this.cognitoUser.username, this.selectedFile)
      .subscribe(userPic => console.log('img sent!!!!', userPic));
  }
}
