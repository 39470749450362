import { Component, OnInit } from '@angular/core';
import { MapMachine } from '../../+xstate/service';

@Component({
  selector: 'app-export',
  templateUrl: './export.component.html',
  styleUrls: ['./export.component.scss'],
})
export class ExportComponent implements OnInit {
  device: any;
  history: any;
  csvContent: any;
  constructor(private xstate: MapMachine) {}

  ngOnInit() {
    this.xstate.selected$.subscribe(device => {
      this.device = device;
    });
    this.xstate.deviceHistory$.subscribe(history => {
      this.history = history;
      this.updateCsvContent();
    });
  }

  updateCsvContent() {
    const items = this.history.items.map(i => {
      const date = new Date(i.date * 1000);
      const dateString = date.toDateString();

      const row = [dateString, i.battery_level, i.type, i.data, i.format, i.position.lat, i.position.lng];
      return row;
    });

    const rows = [['date', 'battery_level', 'type', 'data', 'format', 'latitude', 'longitude'], ...items];

    this.csvContent = 'data:text/csv;charset=utf-8,' + rows.map(e => e.join(',')).join('\n');
  }

  download() {
    const encodedUri = encodeURI(this.csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', `${this.device.name}.csv`);
    document.body.appendChild(link); // Required for FF

    link.click();
  }
}
