import { Component, OnInit } from '@angular/core';
import { fuseAnimations } from '@fuse/animations';
import { APIService } from 'app/services/api.service';
import { CognitoUtil } from 'app/services/cognito.service';
import { Devices } from 'app/classes/devices';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  animations: fuseAnimations
})
export class NotificationsComponent implements OnInit {
  cognitoUser: any;
  notifications: any;
  deviceId: any;
  // devices: Devices;
  devices: any;
  sortedItems: any;

  constructor(
    private apiService: APIService,
    private cognitoUtil: CognitoUtil,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this.getDevicesForUser();

    setTimeout(() => {
      this.getNotifications();
    }, 2000);
  }

  getNotifications() {
    this.cognitoUser = this.cognitoUtil.getCurrentUser();
    // this.deviceId = this.devices.items[0].id;
    setTimeout(() => {
      this.spinner.hide();
    }, 1000);
    /*return this.apiService
      .getNotificationsForABeeping(this.cognitoUser.username, this.devices.items[0].id)
      .then(notifs => {
        this.notifications = notifs;
        this.sortedItems = this.notifications.items.sort((a: any, b: any) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
        );
        console.log('notifsssssss', this.notifications);
      })
      .catch(err => console.log(err));*/
    return this.apiService
      .getNotifications(this.cognitoUser.username)
      .subscribe(
        notifs => {
          this.notifications = notifs;
          this.sortedItems = this.notifications.items.sort(
            (a: any, b: any) =>
              new Date(b.date).getTime() - new Date(a.date).getTime()
          );
          console.log('notifsssssss', this.notifications);
        },
        err => {
          console.log(err);
        }
      );
  }

  getDevicesForUser() {
    this.cognitoUser = this.cognitoUtil.getCurrentUser();
    this.spinner.show();

    // return this.apiService
    //   .getDevicesFromUser(this.cognitoUser.username)
    //   .then(devices => {
    //     this.devices = devices;

    //     console.log('devicesssssss', this.devices);
    //   })
    //   .catch(err => console.log(err));
    return this.apiService
      .getDevicesFromUser(this.cognitoUser.username)
      .subscribe(
        devices => {
          this.devices = devices;
          console.log(this.devices.items);
        },
        err => {
          console.log(err);
        }
      );
  }
}
